/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { ContactUs as Form } from '@red-build/leadcentre';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { BASE_URI, DEALERID } from '../Constants';
import { DealerContext } from '../contexts/DealerContext';
import Staff from '../Staff/Staff';
// import { ReactComponent as ContactUsBG } from '../assets/swoosh-narrow-green.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '60px',
    backgroundImage: `url("swoosh-narrow-green.svg")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#f5f5f5',
    '@media (max-width: 600px)': {
      padding: '0px 0px',
    },
  },
  innerSection: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    padding: '6px 15px 75px 15px',
    justifyContent: 'space-between',
    background: ({ props }) => `${props.home ? 'white' : 'unset'}`,
    borderRadius: '10px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  innerSectionMap: {
    width: '100%',
    maxWidth: '1260px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '6px',
    justifyContent: 'space-evenly',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  innerSectionMapIframe: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    justifyItems: 'space-between',
    minWidth: 'calc(100% / 3 - 40px)',
    maxWidth: 'calc(100% / 3 - 40px)',
    margin: '10px',
    background: 'white',
    padding: '5px',
    borderRadius: '4px',
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  textBold: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  titleTop: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
  black: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: 'white!important',
  },
  yellow: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    fontWeight: 'bold',
    color: 'white!important',
  },
  btn: {
    backgroundColor: '#f0f8ff00',
    border: 'none',
    padding: '10px ',
    cursor: 'pointer',
    color: 'white!important',
  },
  dividerTitle: {
    backgroundColor: ({ websiteColors }) => `${websiteColors.accentColor}`,
    width: '100%',
    height: '3px',
    margin: '10px auto',
  },
  dealerName: {
    fontSize: '18px',
    margin: '5px 0px',
    fontWeight: 'bold!important',
  },
  flexHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
  h1: {
    fontWeight: 500,
    fontSize: '50px',
    marginTop: '0px',
    '@media (max-width: 600px)': {
      marginTop: '50px',
    },
  },
  socialLink: {
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ContactUs = (props) => {
  const { globalDealer, dealerList, dealerListDirty, websiteColors } = useContext(DealerContext);
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles({ websiteColors, props });

  const { key } = props;

  const cleanName = (dealerName) => dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-');

  return (
    <div className={classes.root} id={key} key={key}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <h2 className={classes.h1}>CONTACT US</h2>

          <Typography variant="body2" className={classes.text}>
            Leave a message, we will get back to you.
          </Typography>
          <div className={classes.flexHolder}>
            <div style={{ width: '30%' }}>
              <div>
                <Typography variant="body2" className={classes.textBold}>
                  Contact Us
                </Typography>
              </div>
              <div className={classes.fontSmall}>
                <a
                  href={`tel:${globalDealer?.newCarsPhoneNumber}`}
                  target="_blank"
                  style={{ fontSize: '15px' }}
                  rel="noreferrer"
                >
                  {globalDealer?.newCarsPhoneNumber}
                </a>
              </div>
            </div>
            <div>
              <div style={{ paddingTop: 10 }}>
                <Typography variant="body2" className={classes.textBold}>
                  Address
                </Typography>
              </div>
              <a
                href={`https://www.google.com/maps/@${globalDealer?.addressLongitude},${globalDealer?.addressLatitude},16z?entry=ttu`}
                target="_blank"
                style={{ fontSize: '15px' }}
                rel="noreferrer"
              >
                {globalDealer?.address?.postalAddress}, {globalDealer?.address?.postalCode}
              </a>
            </div>
            <div style={{ paddingTop: 10 }}>
              <Typography variant="body2" className={classes.textBold}>
                Social
              </Typography>
            </div>
            <a
              className={classes.socialLink}
              onClick={() => {
                window.open('https://www.facebook.com/VespaSA/', '_blank');
              }}
            >
              <FacebookIcon className={classes.socialLinkIcon} /> Facebook
            </a>
            <a
              className={classes.socialLink}
              onClick={() => {
                window.open('https://www.instagram.com/vespa_sa/', '_blank');
              }}
            >
              <InstagramIcon className={classes.socialLinkIcon} /> Instagram
            </a>
          </div>
          <hr />
          <Staff />
        </div>
        <div className={classes.section}>
          <h2 className={classes.h1}>ENQUIRE NOW</h2>
          <Form
            dealerId={globalDealer?.dealerId}
            base_uri={BASE_URI}
            leadTypeId={3}
            masterDealerId={DEALERID}
            activeDealerId={globalDealer?.dealerId}
            dealerName={globalDealer?.name?.replace('Master', ' ')}
            dealers={dealerList}
            note
          />
        </div>
      </div>
      <iframe
        title="Our Dealers"
        src={`https://maps.google.com/maps?q=${globalDealer?.addressLongitude},${globalDealer?.addressLatitude}&via=0&z=16&output=embed`}
        width="100%"
        height="335px"
        frameBorder="none"
      />
    </div>
  );
};

export default ContactUs;
