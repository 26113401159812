/* eslint-disable no-plusplus */
import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { makeStyles, Typography } from '@material-ui/core';
import { DealerContext } from '../contexts/DealerContext';
import { BASE_URI } from '../shared/Constants';

let index = 0;
const SORTED_TITLES = {
  'New Vehicles': ++index,
  'Used Vehicles': ++index,
  'Pre Owned Vehicles': ++index,
  'F And I': ++index,
  Service: ++index,
  'Parts And Accessories': ++index,
  'Dealership Driver': ++index,
  Reception: ++index,
  CRM: ++index,
  Administration: ++index,
  Cleaning: ++index,
  Franchise: ++index,
  Washbay: ++index,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  StaffHolder: {
    backgroundColor: 'green',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    width: '90%',
    margin: '0 auto',
    '@media (max-width: 600px)': {
      width: 'unset',
    },
    '@media (min-width: 601px) and (max-width: 1770px)': {
      width: 'unset',
    },
  },
  StaffCard: {
    width: '100%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
    '@media (min-width: 769px) and (max-width: 1770px)': {
      width: '100%',
    },
  },
  StaffCardImgHolder: {
    lineHeight: '0px',
  },
  StaffCardText: {
    color: 'black',
  },
  sectionTitleContainerMiddle: {
    paddingTop: '50px',
    paddingBottom: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'no-wrap',
  },
  sectionTitleTop: {
    fontFamily: 'OmodaL',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '22px',
    fontWeight: 400,
    color: 'black',
  },
  sectionTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1,
    zIndex: 2,
    width: 'fit-content',
    color: theme.palette.secondary.main,
    borderBottom: `5px solid ${theme.palette.primary.main}`,
    margin: '40px auto',
  },
  sectionTitleBackgroundLight: {
    paddingLeft: '50px',
    textTransform: 'uppercase',
    fontSize: '7rem',
    fontWeight: 600,
    zIndex: 1,
    color: '#f3f3f3',
    position: 'absolute',
    '@media (max-width: 959px)': {
      fontSize: '3rem',
    },
  },
  staffContainer: {
    display: 'flex',
    padding: '10px 20px 10px 0px',
    flexDirection: 'column',
    maxWidth: 'calc (40% - 20px)',
  },
}));

function Staff(props) {
  const { globalDealer } = useContext(DealerContext);
  const [staff, setStaff] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [jobTitles, setJobTitles] = useState([]);
  const classes = useStyles({ addTopMargin: props?.addTopMargin });

  useEffect(() => {
    axios.get(`${BASE_URI}/DealerStaff`, {}).then((res) => {
      const staffData = res.data;
      axios.get(`${BASE_URI}/DealerStaff/Department`, {}).then((result) => {
        // console.log('res', result);
        const filtered = staffData?.filter((s) => s.dealerId === globalDealer.dealerId);
        const arr = [];
        for (let i = 0; i < filtered.length; i++) {
          const staffElement = filtered[i];
          for (let j = 0; j < result.data.length; j++) {
            const departmentElement = result.data[j];
            if (staffElement.departmentId === departmentElement.id) {
              if (departmentElement.name.trim() === 'Used Vehicles') {
                staffElement.department = 'Pre Owned Vehicles';
                arr.push(staffElement);
              } else {
                staffElement.department = departmentElement.name.trim();
                arr.push(staffElement);
              }
            }
          }
        }

        const group = arr.reduce((r, a) => {
          switch (a.department) {
            case 'CRM':
            case 'Cleaning':
              // eslint-disable-next-line no-param-reassign
              r.Administration = [...(r.Administration || []), a];
              break;
            case 'Used Vehicles':
              // eslint-disable-next-line no-param-reassign
              r['Pre Owned Vehicles'] = [...(r['Pre Owned Vehicles'] || []), a];
              break;
            default:
              // eslint-disable-next-line no-param-reassign
              r[a.department] = [...(r[a.department] || []), a];
              break;
          }
          return r;
        }, {});
        axios.get(`${BASE_URI}/DealerStaff/JobTitle`, {}).then((r) => {
          setJobTitles(r.data);
        });
        setStaff(group);
      });
    });
  }, [globalDealer]);

  return (
    <div className={classes.root}>
      {Object.keys(staff)
        .sort((a, b) => SORTED_TITLES[a] - SORTED_TITLES[b])
        .map((title) => {
          const s1 = staff[title] || [];
          if (s1.length === 0) return null;
          return (
            <div className={classes.staffContainer} key={`s_card_${title}`}>
              <Typography className={classes.sectionTitleTop}>{title}</Typography>
              {s1.map((s) => (
                <div key={`s_card_${s?.id}`} className={classes.StaffCard}>
                  <div className={classes.StaffCardText}>
                    <Typography style={{ fontFamily: 'OmodaR', fontSize: '15px', color: 'black' }}>
                      Name: {s.fullName}
                    </Typography>
                    <a
                      href={`mailto: ${s.email}`}
                      target="_blank"
                      style={{ fontSize: '15px', color: 'black' }}
                      rel="noreferrer"
                    >
                      <Typography
                        style={{ fontFamily: 'OmodaR', fontSize: '15px', color: 'black' }}
                      >
                        Email: {s.email}
                      </Typography>
                    </a>
                    <a
                      href={`tel:${s.contactNumber}`}
                      target="_blank"
                      style={{ fontSize: '15px', color: 'black' }}
                      rel="noreferrer"
                    >
                      <Typography
                        style={{ fontFamily: 'OmodaR', fontSize: '15px', color: 'black' }}
                      >
                        Tel: {s.contactNumber}
                      </Typography>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
    </div>
  );
}
export default Staff;
