/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
// import { BASE_URI, OMODA_DEALER_ID, MASTER_DEALER_NAME } from './Constants';
import { DealerContext } from './contexts/DealerContext';

const drawerWidth = 240;

const navBarStyles = makeStyles((theme) => ({
  Container: {
    background: 'green',
    color: ({ primaryColor }) => `${primaryColor} !important`,
  },
  background: {
    '@media (max-width: 959px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toolbarRight: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    maxHeight: '40px',
    height: 'auto',
    paddingRight: '45px',
    backgroundColor: '#eee',
    '@media (max-width: 1024px)': {
      justifyContent: 'center',
      height: 'auto',
      display: 'none',
    },
  },
  flexContainer: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
  },
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
      '@media (max-width: 410px)': {
        marginLeft: '3px',
      },
    },
  },
  flexWrap: {
    '@media (max-width: 410px)': {
      flexWrap: 'wrap',
    },
  },
}));

export default function NavbarComponent() {
  const { websiteColors } = useContext(DealerContext);
  const classes = navBarStyles({ ...websiteColors });
  const theme = useTheme();

  return (
    <div className={classes.toolbarRight}>
      <p>Rola Vespa Stellenbosch | a Member of the Rola Motor Group</p>
    </div>
  );
}
