/* eslint-disable react/no-unknown-property */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { CurrencyValue } from '../shared/TextMask';
import { ImageContainerUsedVehicle } from '../shared/ImageContainerUsedVehicle';
import ComingSoon from '../assets/comingsoon.jpg';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 700,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row!important',
    justifyContent: 'space-between',
  },
  flexWrap: {
    display: 'flex',
    flexDirection: 'row!important',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (min-width:0px) and (max-width:425px)': {
      flexDirection: 'column!important',
    },
  },
  title: {
    fontSize: '1.0rem',
    '&>span': {
      fontWeight: 400,
    },
  },
  btnGrey: {
    display: 'flex',
    background: '#404041',
    flexDirection: 'column',
    alignItems: 'end',
    marginTop: '2%',
    width: '45%',
    textTransform: 'lowercase',
    borderRadius: '2rem',
  },
  btn2: {
    minWidth: '100%',
  },
  image: {
    '& > div > div': {
      backgroundColor: '#ffffff !important',
    },
  },
  carsLoadContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  CarsLoad: {
    margin: 'auto',
  },
  flexCard: {
    width: 'calc(100% / 3 )',
    marginBottom: '20px',
    '@media (min-width:0px) and (max-width:425px)': {
      width: 'calc(100% / 1 )',
    },
    '@media (min-width:426px) and (max-width:900px)': {
      minWidth: 'calc(100% / 2 - 5px)',
    },
    '@media (min-width:901px) and (max-width:1222px)': {
      minWidth: 'calc(100% / 3 - 20px)',
    },
  },
  vehicleContainer: {
    margin: '0px 10px',
    border: '1px solid black',
    borderRadius: '2rem',
    backgroundColor: '#fff',
    '& h5, & p': {
      color: '#7c7c7c',
    },
    '&:hover img, &:hover p, &:hover h5': {
      color: '#1f2532',
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  btn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '2%',
    width: '45%',
    borderRadius: '40px',
    background: '#404041',
  },
  btn1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: '2%',
    width: '45%',
    color: 'white',
    background: '#0089a1',
    border: 'none!important',
    borderRadius: '40px',
  },
  textHolder: {
    padding: '20px',
  },
  brand: {
    padding: '0px',
    margin: '0px',
    fontSize: '1.8rem',
  },
  modelRange: {
    padding: '0px',
    margin: '0px',
    color: '#25d1ef!important',
  },
  titleSmall: {
    padding: '0px',
    margin: '0px',
    fontSize: '0.8rem',
  },
  price: {
    fontSize: '2.5rem!important',
    textAlign: 'left',
    fontWeight: 'bold!important',
    color: '#0089a1!important',
    '&>span': {
      fontWeight: 400,
    },
  },
}));

const UsedVehiclesHome = ({ vehicles }) => {
  const classes = useStyles();

  const buildName = (vehicle) => {
    if (!vehicle) return '';
    return `${vehicle.brand} ${vehicle.model}`;
  };

  return (
    <MuiThemeProvider>
      <div md={12} className={classes.flexWrap}>
        {vehicles
          .filter((v) => v.stockNo.includes('62VES' || '62DVE'))
          .map((vehicle, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} className={classes.flexCard}>
              <Box className={classes.vehicleContainer}>
                <Box
                  className={classes.image}
                  onClick={() => {
                    window.open('https://www.rola.co.za/cars-for-sale/vespa', '_blank');
                  }}
                >
                  <ImageContainerUsedVehicle
                    aspectRatio="auto auto"
                    height="222px"
                    position="relative"
                    src={vehicle.image ? vehicle.image : ComingSoon}
                    alt={buildName(vehicle.title)}
                  />
                </Box>
                <div className={classes.textHolder}>
                  <Grid item md={12} mt={0} mb={0} className={`${classes.flex} ${classes.bold}`}>
                    <Grid item xs={12} sm={12} md={12}>
                      <p className={classes.brand}>
                        {vehicle.year} {vehicle.brand}
                      </p>
                      <p className={classes.modelRange}>{vehicle.model}</p>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className={classes.price}>
                    <CurrencyValue value={vehicle.price} />
                  </Grid>
                  <p className={classes.titleSmall}>
                    {vehicle.mileage} km | {vehicle.transmission} | {vehicle.colour}
                  </p>
                  <Box className={classes.flex}>
                    <Button
                      variant="contained"
                      gutterBottom
                      className={classes.btn1}
                      onClick={() => {
                        window.open('https://www.rola.co.za/cars-for-sale/vespa', '_blank');
                      }}
                    >
                      View All Stock
                    </Button>
                  </Box>
                </div>
              </Box>
            </Grid>
          ))}
      </div>
    </MuiThemeProvider>
  );
};

export default UsedVehiclesHome;
