import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import { MetaTags } from '@red-build/leadcentre';
import HomeComponent from './HomeComponent/HomeComponent';
import TopBar from './TopBar/TopBar';
import Footer from './Footer/Footer';
import NotFound from './NotFound/NotFound';
import { DealerContext } from './contexts/DealerContext';
import Popi from './Legal/Popia';
import Terms from './Legal/Terms';

export default function DashboardContainerVespa() {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();
  return (
    <main>
      <TopBar />
      <Switch>
        <Route exact path="/" render={() => <HomeComponent />} />
        <Route exact path="/privacy" render={() => <Popi />} />
        <Route exact path="/terms" render={() => <Terms />} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <ScrollUpButton />
      <MetaTags dealer={globalDealer} pathname={pathname} />
    </main>
  );
}
