import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { DealerContext } from '../contexts/DealerContext';
import { BASE_URI, VESPA_DEALER_ID } from '../Constants';
import Carousel from '../shared/Carousel/SharedCarousel';
import ContactUs from '../ContactUs/ContactUs';
// import AboutUs from '../AboutUs/AboutUs';
import gts from '../assets/VespaAssets/GTS_300_Collection.png';
import gtv from '../assets/VespaAssets/GTV_300.png';
import prima from '../assets/VespaAssets/Primera_Collection.png';
import unique from '../assets/VespaAssets/Unique_Editions.png';
import vxl from '../assets/VespaAssets/VXL.png';
import VespaBanner from '../assets/Vespa_Banner.jpg';
import UsedVehicles from '../usedCarUsedVehiclesPageHome/usedCarUsedVehiclesPageHome';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '150px',
    padding: '0px 0px',
    backgroundImage: `url("swoosh-narrow-blue.svg")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'right bottom',
    '@media (min-width:0px) and (max-width:425px)': {
      flexWrap: 'wrap',
    },
    '@media (max-width:1024px)': {
      paddingTop: '50px',
    },
  },
  section: {
    width: '540px',
    height: '540px',
    margin: '0px 15px',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    '@media (min-width:0px) and (max-width:425px)': {
      height: '240px',
      margin: '15px',
    },
    '@media (min-width:426px) and (max-width:1024px)': {
      height: '340px',
      margin: '15px',
    },
  },
  ImgSection: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    padding: '0px 100px',
    '@media (min-width:0px) and (max-width:768px)': {
      height: 'calc(50vh - 63px)',
      padding: '50px',
    },
    '@media (min-width:769px) and (max-width:1024px)': {},
  },
  SwiperSection: {
    cursor: 'pointer',
  },
  SwiperSectionActionHolder: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    marginBottom: '60px',
  },
  SwiperContent: {
    textAlign: 'center',
    padding: '0px 40px',
    paddingBottom: '40px',
  },
  SwiperContentHeading: {
    fontFamily: 'OmodaR',
    fontSize: '44px',
  },
  SwiperContentText: {
    fontFamily: 'OmodaR',
    fontSize: '16px',
  },
  SwiperInActive: {
    opacity: '0.1',
  },
  ImgSectionHeading: {
    fontFamily: 'OmodaL',
    fontWeight: 450,
    fontSize: '90px',
    margin: '0px',
    lineHeight: '97px',
    color: 'white',
  },
  ImgSectionHeadingSub: {
    fontSize: '30px',
    margin: '0px',
    color: 'white',
  },
  ImgSectionHeadingBtnHolder: {
    marginTop: '32px',
    marginLeft: '-20px',
    '@media (max-width: 448px)': {
      display: 'none',
    },
  },
  ImgSectionHeadingBtn: {
    fontFamily: 'OmodaR',
    fontWeight: 'lighter',
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    border: '1px solid #30363a',
  },
  SwiperSectionActionBtn: {
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    background: 'white',
    border: '1px solid #30363a',
  },
  contactPopUpHolder: {
    position: 'fixed',
    background: '#000000a8',
    width: '100%',
    height: '100vh',
    top: '0px',
    zIndex: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: '40%',
    padding: '20px',
    '@media (max-width: 425px)': {
      width: '90%',
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    fontFamily: 'OmodaR',
    color: 'red',
    textAlign: 'right',
    padding: '0px 0px',
    fontSize: '20px',
    cursor: 'pointer',
    paddingBottom: '10px',
  },
  mButtonHolder: {
    display: 'none',
    '@media (max-width: 448px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  mHeadingBtn: {
    display: 'flex',
    flex: 1,
    padding: 15,
    margin: '10px 20px 10px 20px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: 'OmodaR',
    fontSize: 16,
  },
  textHeader: {
    display: 'flex',
    padding: '20px',
    paddingTop: '40px',
    width: '100%',
    fontFamily: 'OmodaL',
    fontWeight: 800,
    fontSize: '50px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
  },
  HolderTop: {
    width: '100%',
    // height: 250,
    display: 'flex',
    padding: '0px 20px',
    paddingBottom: '0px',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  innerHolderTopItem: {
    display: 'flex',
    flex: 1,
    width: 'calc(100% / 4 - 20px)',
    minHeight: '245px',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    // borderWidth: 1,
    // borderColor: ({ websiteColors }) => `${websiteColors?.secondaryColor}`,
    boxShadow: '2px 2px 10px 1px grey',
    margin: 20,
    padding: 20,
    // borderStyle: 'solid',
    borderRadius: 10,
    '@media (min-width:0px) and (max-width:768px)': {
      width: 'calc(100% - 40px)',
    },
  },
  ctaTitle: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors?.secondaryColor}`,
  },
  h1: {
    color: '#545454',
    letterSpacing: '0em',
    fontWeight: 500,
    fontSize: '50px',
    marginTop: '0px',
    '@media (max-width: 600px)': {
      marginTop: '50px',
    },
  },
  ContactUsBtn: {
    fontWeight: 500,
    fontSize: '28px',
    padding: '15px',
    color: '#6dccb0',
    border: '1px solid #6dccb0',
    borderRadius: '50px',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#6dccb0',
      color: '#fff',
    },
  },
  GridContainer: {
    '&:hover': {
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
  },
  VehicleEnquiryBtn: {
    fontWeight: 500,
    fontSize: '22px',
    padding: '15px',
    color: '#6dccb0',
    border: '1px solid #6dccb0',
    borderRadius: '50px',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#6dccb0',
      color: '#fff',
      border: '1px solid #fff',
    },
  },
  textarea: {
    border: '1px black solid',
  },
  vehicleTitle: {
    fontSize: '32px',
  },
  vehiclePrice: {
    fontSize: '45px',
  },
  vehicleBanner: {
    cursor: 'pointer',
  },
  singleVehicle: {
    display: 'flex',
    width: 'calc(100% / 5 - 10px)',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-evenly',
    padding: '10px',
    margin: '5px',
    border: 'none',
    borderColor: ({ randomColor }) => `${randomColor()}`,
    '&:hover': {
      cursor: 'pointer',
      borderColor: ({ randomColor }) => `${randomColor()}`,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '15px',
      paddingTop: '-2px',
    },
    '@media (max-width: 1800px)': {
      width: 'calc(100% / 5 - 10px)',
      flexWrap: 'wrap',
    },
    '@media (max-width: 1260px)': {
      width: 'calc(100% / 3 - 10px)',
      flexWrap: 'wrap',
    },
    '@media (max-width: 1010px)': {
      width: 'calc(100% - 10px)',
      flexWrap: 'wrap',
    },
  },
}));

const HomeComponent = () => {
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const [slides, setSlides] = useState();
  const [currentVehicleData, setCurrentVehicleData] = useState({});

  const randomColor = () => {
    const aColors = ['#6DCCB0', '#FFD300', '#3EB3E8', '#DC006E', '#320071'];
    const random = Math.floor(Math.random() * 5);
    return aColors[random];
  };

  const classes = useStyles({ websiteColors, randomColor });

  useEffect(() => {
    const params = {
      dealerId: globalDealer?.dealerId || VESPA_DEALER_ID,
      pageNumber: 1,
      pageSize: 25,
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
      }));

      setSlides(tempSlides);
    });
  }, []);

  const aModels = [
    {
      name: 'VXL',
      img: vxl,
      // banner: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-desk-banner-scaled.webp',
      // fullName: 'VESPA VXL 150',
      // about:
      //   'The  VXL 150 is the latest addition to the Vespa family, and our most affordable option yet! The new colours include Matt Rosso Dragon, Matt Yellow and Azzuro Provenza with machine cut alloy wheels. Powering this Vespa 150 range is a 154.8cc single-cylinder, air-cooled motor. ',
      // xImg1:
      //   'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-right-front-three-quarter.webp',
      // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
      // xImg3: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-right-side-throttle-grip.webp',
      // xImg4: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-instrument-cluster-1.webp',
    },
    {
      name: 'PRIMAVERA COLLECTION',
      img: prima,
      variants: [
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/Black_Premavera_New_Page.png',
          fullName: 'PRIMAVERA',
          about: `Vespa has been driving whole generations of young people for over 70 years and one of the most important models in this long story is certainly the Vespa Primavera.\n
                  Originally launched in 1968, the legend has now returned with a totally new and future looking design. It’s limited weight and best-in-category ergonomics make it perfect for trouble-free travel about town. \n
                  In terms of safety too, the Primavera raises the bar thanks to its highly rigid steel body, 200 mm disc brake, new front suspension and ABS safety system. \n
                  A full range of modern and efficient engines and a superior level collection of colours and combinations offer optimum choices for everyone’s needs. \n
                  Please note: images may not be a true representation.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/bike-slider-1.jpg',
          xImg2: '',
          xImg3: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-bg.jpg',
          xImg4: '',
        },
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-e.jpg',
          fullName: 'BE A PART OF THE REVOLUTION',
          about: `The name says it all. The Primavera (RED) is a homage not only to the incredible work carried out by the (RED) initiative but also the revolutionary spirit that has always been at the heart of Vespa, the brand that reinvented the way we get about. With its top to toe livery in stunning red, it’s clear that this set of two wheels is not only bold and daring but also generous and unique. \n
                  It was the 1970s when the Vespa Primavera burst onto the scene like a breath of fresh air. The world was changing and its two wheels and engine combo was greeted with open arms. Today, the Primavera shows it remains in step with the ever-changing times, with new fresh, light lines. And, to its legendary status as an elegant style setter, we can now add a caring spirit of generosity.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-f.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
      ],
    },
    {
      name: 'GTS 300 COLLECTION',
      img: gts,
      variants: [
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/GTS-300-1920-x-1200-1.png',
          fullName: 'GTS 300 HPE',
          about: `Vespa gts evolves in design and technologies, maintaining its spontaneous characters And all the charm of an authentic italian icon. Studied down to the smallest detail, it Preserves the charm of the timeless lines, emphasising the modern and technological Traits. A painstaking job that emerges clearly already from the front view, thanks to The new turn indicators and daylight running lights that now take advantage of led Technology, the unmistakeable redesigned neck-tie, and the brand new mudguard crest. The handlebar, with the characteristic full led circular headlamp, has been entirely Redesigned, incorporating new hand grips and new controls. \n
                  At the rear, the taillight Unit has also been revisited, with led turn indicators and a new position light. The new Vespa gts range has the most recent evolution of the single-cylinder 300 cc hpe (high Performance engine), 4-stroke, 4 valves, liquid cooled, and electronic injection, with Better performance and reduced fuel consumption. All models in the new vespa gts Range are also available with a 125 I-get electronic injection engine, with four valve Distribution and liquid cooling. An engine that tops its category in terms of performance And respect for the environment. Enjoy the trip and vespa gts will take care of the rest.`,
          // xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/bike-slider-1.jpg',
          // xImg2: '',
          // xImg3: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-bg.jpg',
          // xImg4: '',
        },
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/GTS-300-SUPER-1920-x-1200.png',
          fullName: 'GTS 300 SUPER HPE​',
          about: `Aggressive and popular for its chromium finishing combined with sporty Details in black such as the hand grips and the rubber inserts on the Footboards, as well as the front suspension spring, vespa gts super Interprets the typical vespa gts values of style, comfort, and safety, also Adding a touch of sportiness. The sleek and flowing side, with the new Grilles, the new side panels and the new, more dynamic design of the 12’’ Wheels (on the entire range) is recognisable at a glance on vespa gts super With its mirror-polished graphite colour 5-spoke rims. \n 
                  The ergonomic seat is The result of a search for just the right materials, stitching, and textures To provide the best on-board experience, with an eye to style. The braking System on the new vespa gts range includes a front and rear disc brake with Abs and asr (only on the 300 cc version), for absolutely safe riding.`,
          // xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-f.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
        {
          banner:
            'https://vespa.co.za/wp-content/uploads/2023/01/GTS-300-SUPERSPORT-1920-x-1200-1.png',
          fullName: 'GTS 300 SUPER SPORT',
          about: `Vespa gts supersport is the pinnacle of sportiness a strong and gritty character, Enhanced by its graphite colour wheel rims with dedicated graphic and numerous Black details, beginning with the profile that runs along the perimeter of the Body. The typical “neck-tie” on the front shield has a brand new carbon look Finish and it has been made even more aggressive with its fluorescent orange Embellishments. The saddle is black and characterised by a seating area with Horizontal thermal bonding and the contrasting fluorescent orange double Stitching. \n
                  The braking system on the vespa gts range implements new brembo Callipers and new master cylinders which translate into a reduction of Braking distance and more modular operation, capable of making even the most Inexperienced rider feel at ease. Standard equipment on the vespa gts supersport Includes the vespa mia connectivity system. Powerful, original, and full of energy. Experience your adventures with all the grit you’ve always sought after. Throw a Leg over the vespa gts supersport and show the world what you’re made of.`,
          // xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-f.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
        {
          banner:
            'https://vespa.co.za/wp-content/uploads/2023/01/GTS-300-SUPERTECH-1920-x-1200-1.png',
          fullName: 'GTS 300 SUPER TECH HPE',
          about: `All the latest technologies meet the timeless design of Vespa among brilliant, textured, and matt colours that breathe life into electrifying plays on light and contrast. The technical finishes in warm grey and fluorescent green stand out, as well as the seat with diamond-shaped thermal bonded upholstery and contrasting stitching, not to mention the shapes and painstaking attention to all the details that make Vespa truly inimitable. \n
                  Vespa GTS SuperTech offers a unique riding experience and increases travelling comfort with a few dedicated features such as the helmet compartment with courtesy light and mat. It was the first large-bodied Vespa to adopt a fully digital instrument cluster thanks to a 4.3” full colour TFT display that serves as a true on-board computer. The display also serves as a navigation system, accompanying the rider along the route with directions in the form of pictograms.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-f.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
      ],
    },
    {
      name: 'GTV 300',
      img: gtv,
      variants: [
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/Black_Premavera_New_Page.png',
          fullName: 'VESPA COLLECTION GTV 300',
          about: `From its birth in 2006, Vespa GTV has been the ambassador of purest classicism within the Vespa family thanks to the use of the “faro basso” (low headlamp), positioned on the front mudguard, and the exposed metal tubular handlebar, stylistic throwbacks to the most iconic models. \n
                  At EICMA 2022, Vespa GTV débuts in a completely revolutionized look, maintaining the inevitable traits of the legend’s origins, but combining them with an ultra-modern technological equipment package and brand-new sporty finishes. The result is an extraordinary marriage of tradition and modernity, classicism and aggressiveness, which manifests itself in the most authentically sporty Vespa ever. \n
                  The new Vespa GTV is powered by the gritty 300 hpe (High Performance Engine) single cylinder, with 4-valve timing, liquid cooling, and electronic fuel injection. Accredited with power of 17.5 kW (23.8 HP), it is the highest performance engine ever fitted on a Vespa.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/bike-slider-1.jpg',
          xImg2: '',
          xImg3: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-bg.jpg',
          xImg4: '',
        },
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-e.jpg',
          fullName: 'BE A PART OF THE REVOLUTION',
          about: `The name says it all. The Primavera (RED) is a homage not only to the incredible work carried out by the (RED) initiative but also the revolutionary spirit that has always been at the heart of Vespa, the brand that reinvented the way we get about. With its top to toe livery in stunning red, it’s clear that this set of two wheels is not only bold and daring but also generous and unique. \n
                  It was the 1970s when the Vespa Primavera burst onto the scene like a breath of fresh air. The world was changing and its two wheels and engine combo was greeted with open arms. Today, the Primavera shows it remains in step with the ever-changing times, with new fresh, light lines. And, to its legendary status as an elegant style setter, we can now add a caring spirit of generosity.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-f.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
      ],
    },
    {
      name: 'UNIQUE EDITIONS',
      img: unique,
      variants: [
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/000-Vespa-Pic-Nic.jpg',
          fullName: 'PRIMAVERA PIC NIC',
          about: `All the joy of Vespa in a special version outfitted with basket and picnic blanket, to spend your free time in the most glamorous and carefree way. Two-tone saddle and dedicated graphics characterise a unique equipment package. An icon of the Italian lifestyle, Vespa is also a symbol of freedom, friendship, and sharing. \n
                  In a world that is rediscovering the beauty of experiencing nature, Vespa Primavera is now available in the new and exclusive Pic Nic version, created specifically to flee everyday stress and savour moments of joy and socialising outdoors in the most glamorous and carefree way, accompanies by the light lines, the brilliant performance, and the easy ride of a Vespa.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/0000-Vespa-Pic-Nic.jpg',
          xImg2: '',
        },
        {
          banner:
            'https://vespa.co.za/wp-content/uploads/2023/03/Vespa_Vespa946_Landing_2023_Gallery_1920x1080_1.jpg',
          fullName: 'VESPA 946 "BUNNY"',
          about: `Vespa 946 celebrates its 10th anniversary with a limited and numbered edition of 1,000 vehicles which are dedicated to the lunar calendar. A creative breakthrough that starts a collection that, every year and for the next 12, will feature the animal of the year.\n
                  A unique version, with a “bunny” peeping out of the bodywork,  confirm Vespa 946 as a contemporary interpretation of Vespa values. The brand’s classic green is, in fact, reworked to be sparkling.`,
          xImg1:
            'https://vespa.co.za/wp-content/uploads/2023/03/Vespa_Vespa946_Landing_2023_img_900x675_1-1.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/01/Elettrica-1920-x-1200.png',
          fullName: 'ELETTRICA RED',
          about: `Vespa and non-profit organisation (RED) combine forces to create (VESPA ELETTRICA) RED, a special bike that reflects the shared values of respect for the environment and caring about global health.`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/Content_panel_2.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
          xImg3: '',
          xImg4: 'https://vespa.co.za/wp-content/uploads/2023/01/Content_panel_1.jpg',
        },
        {
          banner: 'https://vespa.co.za/wp-content/uploads/2023/08/IMGfirm1920x1080.png',
          fullName: 'DISNEY MICKEY MOUSE EDITION BY VESPA 150 I-GET',
          about: `Two iconic brands meet in a limited edition collaboration that celebrates a very special anniversary: Vespa presents Disney Mickey Mouse Edition by Vespa. \n
                  The two brands have always been in the zeitgeist thanks to an iconography made up of unmistakable shapes and colours, with Vespa valuing freedom and fun, shared with Disney’s values of storytelling and escapism this makes for the perfect collaboration. \n
                  The über-famous scooter recently became the very materialization of dreams in the fantastic adventures of Luca, Disney and Pixar’s animation masterpiece. Today these two timeless icons come together on a special project for Disney100, with Vespa paying homage to this anniversary through a global collaboration of the two unique brands with a Disney Mickey Mouse Edition by Vespa. Vespa is more than just way of transportation, it is a way of life: the extension of a rider’s personality, of their history and journey. A wonderful travel companion that, in this special edition, sets the imagination free and encourages us to dream big. Michele Colaninno, Chief Executive of global strategy, product, marketing, and innovation of the Piaggio Group, commented on this collaboration as follows: “Dreams allow us to look to the future with optimism, even in complex times such as these. A timeless icon like Vespa could not fail to celebrate, on this very special occasion of Disney’s 100th Anniversary, an equally everlasting icon like Mickey Mouse, with a tribute to creativity, imagination, light-heartedness, and fun – the very values that have always anchored Vespa. \n
                  Today, as then. This collaboration between Vespa and Disney represents the celebration of two historic companies that have a common dream: to allow everyone to express their imagination freely.” Claire Terry, Senior Vice President, Disney Consumer Products, Games & Publishing EMEA said, “Just like Mickey Mouse, Vespa is a cultural icon, evoking a sense of fun and adventure and together they form a wonderful combination, truly extending our timeless storytelling. We are incredibly excited to bring this version of the iconic scooter to our fans in our centenary year and bringing all of Mickey’s charm and personality to road trips wherever people want to go!” For this collaboration, the Vespa Primavera 50cc, 125cc and 150cc are coloured black, red, white and yellow: the same hues that have characterised Walt Disney’s most famous mouse for decades. \n
                  The yellow wheels are reminiscent of Mickey Mouse’s shoes, while the black mirrors recall his unmistakable round ears. A graphic pattern outlining the character’ silhouette decorates both sides of the scooter as well as the front. Finally Mickey Mouse’s signature could not be missed, featured on both the saddle and the front shell. Disney Mickey Mouse Edition by Vespa comes with a matching helmet in the same stunning colours. \n`,
          xImg1: 'https://vespa.co.za/wp-content/uploads/2023/01/primavera-red-125-f.jpg',
          // xImg2: 'https://vespa.co.za/wp-content/uploads/2023/08/vxl-150-handlebar.webp',
        },
      ],
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const backgroundColorsVehicleGrid = ['#6dccb0', '#FFAB91', '#3eb3e8', '#dc006e', '#ffd300'];

  const VehicleGrid = [
    {
      vehicleTitle: 'VXL',
      vehiclePrice: 'R140 000',
      vehicleContent:
        'The  VXL 150 is the latest addition to the Vespa family, and our most affordable option yet! The new colours include Matt Rosso Dragon, Matt Yellow and Azzuro Provenza with machine cut alloy wheels. Powering this Vespa 150 range is a 154.8cc single-cylinder, air-cooled motor.',
      img: vxl,
    },
    {
      vehicleTitle: 'PRIMAVERA COLLECTION',
      vehiclePrice: 'R140 000',
      vehicleContent:
        'The two-wheeler that made Vespa a worldwide phenomenon in 1968 is back and updated with the latest technology. The Primavera offers a robust yet easy ride, with more space between the handlebar and the saddle.',
      img: prima,
    },
    {
      vehicleTitle: 'GTS 300 COLLECTION',
      vehiclePrice: 'R195 000',
      vehicleContent:
        'Vespa GTS evolves in design and technologies, maintaining its spontaneous characters and all the charm of an authentic Italian icon. Studied down to the smallest detail, it preserves the charm of the timeless lines, emphasising the modern and technological traits.',
      img: gts,
    },
    {
      vehicleTitle: 'GTV 300',
      vehiclePrice: 'R210 000',
      vehicleContent:
        'From its birth in 2006, Vespa GTV has been the ambassador of purest classicism within the Vespa family thanks to the use of the “faro basso” (low headlamp), positioned on the front mudguard, and the exposed metal tubular handlebar, stylistic throwbacks to the most iconic models.',
      img: gtv,
    },
    {
      vehicleTitle: 'UNIQUE EDITIONS',
      vehiclePrice: 'POA',
      vehicleContent:
        'All the joy of Vespa in our Unique Editions from the Primavera Pic Nic to the Mickey Mouse eddition. An icon of the Italian lifestyle, Vespa is also a symbol of freedom, friendship, and sharing.',
      img: unique,
    },
  ];

  return (
    <div>
      <div className={classes.root}>
        {slides?.length > 0 && <Carousel slides={slides} />}
        <div
          style={{
            width: '100%',
            padding: '50px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <h1 className={classes.h1}>
            <span
              style={{
                fontSize: '22px',
                color: '#929598',
              }}
            >
              WELCOME TO{' '}
            </span>
            <br />
            ROLA VESPA STELLENBOSCH
          </h1>
          <h2 className={classes.h2}>Select Your Range</h2>
          <div className={classes.HolderTop} style={{ display: 'none' }}>
            {aModels.map((v) => (
              <div
                className={classes.singleVehicle}
                onClick={() => {
                  setCurrentVehicleData(v);
                }}
              >
                <img src={v.img} alt={v.name} style={{ width: '100%' }} />
                <h2>{v.name}</h2>
              </div>
            ))}
          </div>
          <div className={classes.HolderTop}>
            {currentVehicleData ? (
              <div>
                <img src={currentVehicleData?.banner} alt={currentVehicleData.fullName} />
                <h2>{currentVehicleData?.fullName}</h2>
                <p>{currentVehicleData?.about}</p>
                <img src={currentVehicleData?.xImg1} alt={currentVehicleData.fullName} />
                <img src={currentVehicleData?.xImg2} alt={currentVehicleData.fullName} />
                <img src={currentVehicleData?.xImg3} alt={currentVehicleData.fullName} />
                <img src={currentVehicleData?.xImg4} alt={currentVehicleData.fullName} />
              </div>
            ) : null}
          </div>
          <Box sx={{ flexGrow: 1, maxWidth: '1200px' }}>
            <Grid
              container
              spacing={0}
              columns={{ xs: 1, sm: 4, md: 12 }}
              alignItems="stretch"
              justifyContent="center"
            >
              {VehicleGrid.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={1} sm={2} md={4} key={index}>
                  <Item
                    className={classes.GridContainer}
                    style={{
                      justifyContent: 'center',
                      textAlign: 'center',
                      height: '100%',
                      backgroundColor:
                        backgroundColorsVehicleGrid[index % backgroundColorsVehicleGrid.length],
                      borderRadius: 0,
                    }}
                  >
                    <img src={item.img} alt={item.content} style={{ width: '100%' }} />
                    <p className={classes.vehicleTitle}>{item.vehicleTitle}</p>
                    <div style={{ height: '150px' }}>
                      <p className={classes.vehicleContent} style={{ fontSize: '18px' }}>
                        {item.vehicleContent}
                      </p>
                    </div>
                    <p className={classes.vehiclePrice}>
                      <span style={{ fontSize: '16px' }}>from</span> {item.vehiclePrice}
                    </p>
                    <button
                      type="button"
                      className={classes.VehicleEnquiryBtn}
                      onClick={() => {
                        window.open('https://vespa.co.za/', '_blank');
                      }}
                    >
                      Find Out More
                    </button>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>

          <div
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              paddingTop: '25px',
              borderRadius: 0,
            }}
          >
            {/* <button
              type="button"
              className={classes.ContactUsBtn}
              onClick={() => {
                const section = document.getElementById('Contact-Us');
                if (section) {
                  section.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Contact Us
            </button> */}
          </div>
        </div>
        <a
          className={classes.vehicleBanner}
          onClick={() => {
            window.open('https://vespa.co.za/', '_blank');
          }}
        >
          <img alt="Shop Now - Vespa South Africa" src={VespaBanner} width="100%" />
        </a>
        <h2 className={classes.h1}>
          <br />
          Featured Vehicles
        </h2>
        <UsedVehicles />
        <div id="Contact-Us" />
        <ContactUs key="contact-us" />
        {/* <AboutUs /> */}
      </div>
    </div>
  );
};

export default HomeComponent;
