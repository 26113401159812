import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, MOTORGROUPID } from '../shared/Constants';

export default function VehicleControllerHome() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [total, setTotal] = useState(false);

  useEffect(() => {
    let cancel;
    setLoading(true);
    setError(false);
    setVehicles([]);

    const params = {
      pageNumber: 1,
      motorGroupId: MOTORGROUPID,
      pageSize: 1000,
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/stock`,
      params,
      // eslint-disable-next-line no-return-assign
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((response) => {
        setVehicles(response.data?.list);
        setTotal(response.data?.list.length > 0);
        setLoading(false);
      })
      // eslint-disable-next-line no-shadow
      .catch((error) => {
        if (axios.isCancel(error)) return;
        setLoading(false);
      });
    return () => cancel();
  }, []);

  return { loading, error, vehicles, total };
}
