/* eslint-disable no-restricted-globals */
import React, { useContext } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { MetaTagsProvider } from '@red-build/leadcentre';
import DashboardContainerVespa from './VespaComponents/MainContainer';
import { DealerContext } from './VespaComponents/contexts/DealerContext';
import { MakeProvider } from './VespaComponents/contexts/MakeContext';
import { CompareProvider } from './VespaComponents/contexts/CompareContext';
import { FavouriteProvider } from './VespaComponents/contexts/FavouriteContext';
import { VehicleProvider } from './VespaComponents/contexts/VehicleContext';
// import WhatsAppLogo from './VespaComponents/assets/Whatsapp_Logo.png';
import { FinanceProvider } from './VespaComponents/shared/contexts/FinanceContext';

function App() {
  localStorage.clear();
  const { globalDealer } = useContext(DealerContext);

  // const openWhatsApp = () => {
  //   const currentLocation = window.location.href;
  //   const url = `https://wa.me/+27722147141?text=Hi, can you please assist. I am currently viewing the following page: ${currentLocation}`;
  //   window.open(url, '_blank');
  // };

  return (
    <Router>
      <FavouriteProvider>
        <CompareProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <CssBaseline />
            <VehicleProvider>
              <FinanceProvider>
                <MakeProvider>
                  <MetaTagsProvider>
                    {globalDealer && <DashboardContainerVespa />}
                    <div
                      style={{
                        position: 'fixed',
                        bottom: 120,
                        right: 50,
                        height: 30,
                        width: 30,
                        zIndex: 999,
                      }}
                    >
                      {/* <img
                      alt="Contact Rola WhatsApp button"
                      src={WhatsAppLogo}
                      onClick={() => openWhatsApp()}
                      style={{ width: '60px' }}
                    /> */}
                    </div>
                  </MetaTagsProvider>
                </MakeProvider>
              </FinanceProvider>
            </VehicleProvider>
          </SnackbarProvider>
        </CompareProvider>
      </FavouriteProvider>
    </Router>
  );
}

export default App;
