import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { useHistory } from 'react-router-dom';
import VehicleControllerHome from '../usedVehiclesHome/VehicleControllerHome';
import UsedVehiclesHome from '../usedVehiclesHome/UsedVehiclesHome';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(6, 0, 6),
  },
  flexTop: {
    display: 'flex',
    flexDirection: 'column!important',
    justifyContent: 'space-between',
  },
  center: {
    display: 'flex',
    flexDirection: 'column!important',
    margin: '0 auto',
  },
  accent: {
    color: '#0089a1',
  },
  titleh: {
    color: '#545454',
    letterSpacing: '0em',
    fontWeight: 500,
    fontSize: '50px',
    marginTop: '0px',
  },
}));

const UsedCarUsedVehiclesPageHome = () => {
  const history = useHistory();
  const classes = useStyles();
  const filteredData = null;
  const showFilteredData = false;
  const { vehicles } = VehicleControllerHome();

  const onSelectVehicle = (vehicle) => {
    history.push(`/used-vehicles/${vehicle?.stockId}`);
  };

  return (
    <MuiThemeProvider>
      <Container maxWidth="lg" className={classes.content}>
        <Grid md={12}>
          <UsedVehiclesHome
            vehicles={showFilteredData ? filteredData : vehicles}
            onSelectView={onSelectVehicle}
          />
        </Grid>
      </Container>
    </MuiThemeProvider>
  );
};

export default UsedCarUsedVehiclesPageHome;
